import { date_range_picker } from "./date_range_picker";

const branch_menu_items_template = require("../templates/branch_menu_items");

class DrawBranchMenuItem {
   constructor(options) {
      this.target_element_id = $(options.target_element_id);
      this.branch_id = options.branch_id;
      this.menu_items = options.branch_menu_items;
      this.data_target_prefix = options.data_target_prefix;
      this.branch = options.branch;
      this.after_draw_cb = options.after_draw_cb;
      this.delegate_collapse();
      this.delegate_category_click(options.after_category_click_cb);
      this.delegate_menu_item_click(options.after_menu_item_click_cb);
      this.delegate_extras_click(options.after_extra_click_cb);
      this.delegate_options_click(options.after_options_click_cb);
      $('#promotion_shop_discount_percent, #promotion_drivu_percent').on('keyup', function(){
        console.log('keyup')
        console.log( $('#promotion_shop_discount_percent').val());
        var value = parseFloat($('#promotion_shop_discount_percent').val()) + parseFloat($('#promotion_drivu_percent').val())
        $('.promotion-save-btn').val(`save ${value} %`)
      });
  
      this.draw();
    }

    delegate_collapse() {
      this.target_element_id.on('click', '.categories-tab li[data-bs-toggle="collapse"]', function () {
        $('.collapse.in').collapse('hide')
      });
    }

    draw(){
      this.target_element_id.empty();
      var menu_items_temp = branch_menu_items_template({ branch: this.branch, data_target_prefix: this.data_target_prefix });
      this.target_element_id.append(menu_items_temp);
      this.after_draw_cb();
    }

    delegate_category_click(after_category_click_cb) {
      this.target_element_id.on('click', '.category_id', function (e) {
        after_category_click_cb(e);
      });
    }

    delegate_menu_item_click(after_menu_item_click_cb) {
      this.target_element_id.on('click', '.menu-item', function (e) {
        after_menu_item_click_cb(e);
      });
    }

    delegate_extras_click(after_extra_click_cb) {
      this.target_element_id.on('click','.menu-item-extras', function (e) {
        after_extra_click_cb(e);
      });
    }

    delegate_options_click(after_options_click_cb) {
      this.target_element_id.on('click', '.options_ids', function (e) {
        after_options_click_cb(e);
      });
    }
}

class BranchPromotion {
  constructor(options) {
    this.action =  options.action;
    this.branch_id = options.branch_id;
    this.branch = options.branch;
    this.category_ids = options.category_ids;
    this.menu_item_ids = options.menu_item_ids;
    this.extra_ids = options.extra_ids;
    this.option_ids = options.option_ids;
    this.free_menu_item = options.free_menu_item;
    var _this = this;

    // this.on_hours_plugin(options, _this);

    new date_range_picker({
      selector: $("#promotion_ends_at"),
      config: { format: 'YYYY/MM/DD', locale: { cancelLabel: 'Clear', format: 'YYYY/MM/DD' }, singleDatePicker: true },
    });

    new date_range_picker({
      selector: $("#promotion_start_at"),
      config: { format: 'YYYY/MM/DD', locale: { cancelLabel: 'Clear', format: 'YYYY/MM/DD' }, singleDatePicker: true },
    });

    this.draw_menu_item_tree_select();
    
    $('#new_promotion').on('submit', function (e) {
      e.preventDefault();
      var menu_item_element = $('#singel-menu-item-select .menu-item:checked').first();
      var extras = _.map(menu_item_element.parents("tr").find('.menu-item-extras:checked'), function(e){
        return $(e).val();
      });
      var options = _.map(menu_item_element.parents("tr").find('.options_ids:checked'), function(e){
        return $(e).val();
      });

      var category_ids = _.map($("#branch-menu-items .categories-tab .category_id:checkbox:checked"), function (e) { return $(e).val() });
      var menu_item_ids = _.map($("#branch-menu-items  .menu-item:checkbox:checked"), function (e) { return $(e).val() });
      var extra_ids = _.map($("#branch-menu-items  .menu-item-extras:checkbox:checked"), function (e) { return $(e).val() });
      var options_ids = _.map($("#branch-menu-items  .options_ids:checkbox:checked"), function (e) { return $(e).val() });
    
      $("#selected_category_ids").attr("value", JSON.stringify(category_ids));
      $("#selected_option_ids").attr("value", JSON.stringify(options_ids));
      $("#selected_extra_ids").attr("value", JSON.stringify(extra_ids));
      $("#selected_menu_item_ids").attr("value", JSON.stringify(menu_item_ids));

      $('#free-item-menu-item').val(menu_item_element.val());
      $('#free-item-menu-extras').val(JSON.stringify(extras));
      $('#free-item-menu-options').val(JSON.stringify(options));
      // $('#promotion_on_hours').val(JSON.stringify(_this.serializeWorkingHours()));

      return true;
    });

    $('#new_promotion').on('ajax:error', function (e, xhr, status, error){
      $('.errors').empty();
      _.each(xhr.responseJSON.errors, function (text) { $('.errors').append("<li>" + text +"</li>") })
    });

    $('#new_promotion').on("ajax:success", function (e, data, status, xhr){
      $('.errors').empty();
      window.location.href = "/admins/branches/" + _this.branch_id + "/promotions";
    });

    if ($('#deal-type').val() == 2 ){
      this.draw_singel_menu(options);
    }
    
    $('#deal-type').on("change", function(e){
      if ($(e.currentTarget).val() == 2){
        _this.draw_singel_menu(options);
      }else{
        $('#singel-menu-item-select').hide();
      }
    });
  }
  
  draw_singel_menu(options){
    $('#singel-menu-item-select').show();
    new DrawBranchMenuItem({
      branch: this.branch || options.branch,
      action: options.action,
      target_element_id: "#singel-menu-item-select",
      data_target_prefix: "singel-menu-item-select",
      after_draw_cb: function(){
        if(this.free_menu_item != undefined){
          $("#singel-menu-item-select-menu-item-id-"+this.free_menu_item.menu_item_id).prop('checked', true);
          _.each(this.free_menu_item.extras, function (id) { $("#singel-menu-item-select-menu-item-extra-"+id).prop('checked', true)});
          _.each(this.free_menu_item.options, function (id) { $("#singel-menu-item-select-option-"+id).prop('checked', true) });
        }
      }.bind(this),

      after_menu_item_click_cb: function(){
        $("#singel-menu-item-select").on('change', '.menu-item', function(e){
          $('.menu-item').prop("checked", false);
          $(this).prop('checked', true);
        });
      },
      
    });
  }

  serializeWorkingHours() {
    var _this = this;
    return _.map($('.singleDay'), function (e) {
      var day =new  Packs.application.WorkingDay(e);
      console.log(day);
      _this.serializedWorkingHours.push(day);
      return day.serialize()

    });
  }
  
  default_working_hours(){
    $(".dayCheckbox").click();
    $(".timeFrom").val("07:00");
    $(".timeTill").val("18:00");
  }

  on_hours_plugin(options, _this){
    this.serializedWorkingHours = [],


    $("#sun.timeFrom").on('change', function (e) {
      $(".timeFrom").val($("#sun.timeFrom").val());
    });

    $("#sun.timeTill").on('change', function (e) {
      $(".timeTill").val($("#sun.timeTill").val());
    });

    if (options.action == "new") { this.default_working_hours() }

    this.serializeWorkingHours();

    var currentWorkingDays = options.working_hours;

    _.each(currentWorkingDays, function (day) {
      _.find(_this.serializedWorkingHours, function (dayObject) {
        return dayObject.__id == day.day;
      }).renderUI(day);
    });
    
    $('.edit_promotion').on('submit', function () {
      $('#promotion_on_hours').val(JSON.stringify(_this.serializeWorkingHours()));
      return true;
    });
  }

  draw_menu_item_tree_select(){
    new DrawBranchMenuItem({
      target_element_id: "#branch-menu-items",
      branch_id: this.branch_id,
      branch: this.branch,
      data_target_prefix: "multiple-menu-item-select",
      after_draw_cb: function () {
        _.each(this.category_ids, function (id) {
          $('#branch-menu-items').find('.category_id#multiple-menu-item-select-category-checkbox-'+id).trigger('click');
        });

        _.each(this.menu_item_ids, function (menu_item_id) {
          $('#branch-menu-items').find('.menu-item#multiple-menu-item-select-menu-item-id-'+menu_item_id).trigger('click');
        });

        _.each(this.extra_ids, function (extra_id) {
          $('#branch-menu-items').find('.menu-item-extras#multiple-menu-item-select-menu-item-extra-'+extra_id).trigger('click');
        });

        _.each(this.option_ids, function (option_id) {
          $('#branch-menu-items').find('.options_ids#multiple-menu-item-select-option-'+option_id).trigger('click');
        });
      }.bind(this),

      after_category_click_cb: function (e) {
        var parent_target = $(e.currentTarget).parent().data("target");
        $(parent_target).find("input[type=checkbox]").prop("checked", $(e.currentTarget).prop('checked'));
      },

      after_menu_item_click_cb: function (e) {
        var parent_target = $(e.currentTarget).parents("tr");
        $(parent_target).find("input[type=checkbox]").prop("checked", $(e.currentTarget).prop('checked'));
      },

      after_extra_click_cb: function (e) {
        var parent_target = $(e.currentTarget).parent().parent("ul");
        $(parent_target).find("input[type=checkbox]").prop("checked", $(e.currentTarget).prop('checked'));
      }
    });
  }

  delegate_discount_element_change(){
    $('#promotion_shop_discount_percent, #promotion_drivu_percent').on('keyup', function(){
      console.log('keyup')
      console.log( $('#promotion_shop_discount_percent').val());
      var value = parseFloat($('#promotion_shop_discount_percent').val()) + parseFloat($('#promotion_drivu_percent').val())
      $('.promotion-save-btn').val(`save ${value} %`)
    });
  }
}

export { DrawBranchMenuItem, BranchPromotion }

